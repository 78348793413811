import styled, { CreateStyled } from '@emotion/styled'

export interface Theme {
  color: {
    primary: string,
    background: string,
    lightBg: string,
    darkText: string,
  }
}

export default styled as CreateStyled<Theme>
