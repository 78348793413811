import React from 'react'
import { LogoDiv } from './Logo.styles'
import perilleLogo from './1024_logo_center.png'

const Logo = () => {
  return (
    <LogoDiv>
      <img src={perilleLogo} alt="Perille" />
      <p>perille.fi</p>
    </LogoDiv>
  )
}

export default Logo
