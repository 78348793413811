import styled from '../../styled'

export const FooterDiv = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.color.background};
  padding: 0.5rem;
  & > div {
    margin-right: 1rem;
    justify-content: flex-end;
    & > img {
      width: 70px;
    }
  }
`
