import React, { useState } from 'react'
import { useHistory, RouteProps } from 'react-router-dom'
import { Form, AutoComplete, Button } from 'antd'
import { useTheme } from 'emotion-theming'
import Header from '../../components/Header/Header'
import { Theme } from '../../styled'
import { useAutoComplete } from '../../hooks/useAutoComplete'
import { SearchPageStyles, FormContainer } from './SearchPage.styles'

const SearchPage: React.FC<RouteProps> = () => {
  const [fromString, setFromString] = useState('')
  const [toString, setToString] = useState('')
  const { places: fromPlaces } = useAutoComplete(fromString)
  const { places: toPlaces } = useAutoComplete(toString)
  const history = useHistory()
  const theme = useTheme<Theme>()

  return (
    <SearchPageStyles>
      <Header title="Perille screen" />
      <FormContainer>
        <Form style={{ width: '400px' }}>
          <Form.Item style={{ margin: 0 }}>
            <AutoComplete
              data-testid="fromField"
              placeholder="Mistä"
              value={fromString}
              onChange={value => setFromString(value as string)}
              dataSource={fromPlaces.map(place => place.string || '')}
              onSearch={value => setFromString(value)}
            />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <AutoComplete
              data-testid="toField"
              placeholder="Minne"
              value={toString}
              onChange={value => setToString(value as string)}
              dataSource={toPlaces.map(place => place.string || '')}
              onSearch={value => setToString(value)}
            />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              onClick={() => history.push(`/results/${fromString}/${toString}`)}
              style={{
                width: '100%',
                backgroundColor: theme.color.primary,
                border: 'none',
              }}
              type="primary"
              htmlType="submit"
              color={theme.color.primary}
            >
              Etsi
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </SearchPageStyles>
  )
}

export default SearchPage
