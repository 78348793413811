import moment from 'moment'
import 'moment-duration-format'
import uuid from 'uuid'
import {
  Connection as IConnection,
  ConnectionLeg as IConnectionLeg,
  GenericLocalLeg as IGenericLocalLeg,
  Note,
  Price,
  Distance,
  Action,
  Weight,
  MultiLanguageString,
  Emission,
  Time,
  Place,
  LegalNotice,
} from '../types/api/generatedTypes'

class Connection implements IConnection {
  public legs: (ConnectionLeg | GenericLocalLeg)[]
  public id: string
  public legTypes: string[]
  public notes?: Array<Note>
  public price?: Price
  public distance?: Distance
  public duration?: Distance
  public actions?: Array<Action>
  public availability?: string
  public amenities?: Array<string>
  public connectionId?: string
  public retrievedTime?: Date
  public weights?: Array<Weight>

  constructor(connection: IConnection) {
    this.id = uuid()
    Object.assign(this, connection)
    this.legTypes = connection.legTypes
    this.legs = connection.legs.map(leg => {
      if (leg.type === 'ConnectionLeg') {
        return new ConnectionLeg(leg as IConnectionLeg)
      }
      return new GenericLocalLeg(leg as IGenericLocalLeg)
    })
  }

  getConnectionLegs(): ConnectionLeg[] {
    return this.legs.filter(
      (leg): leg is ConnectionLeg => leg.type === 'ConnectionLeg',
    )
  }

  getLogoProviders() {
    return this.getConnectionLegs().map(leg => leg.logo || leg.provider)
  }

  getDepartureTime(): string {
    const connectionLegs = this.getConnectionLegs()
    const firstLeg = connectionLegs[0]
    return firstLeg.getTime()
  }

  hasStartAndEndTime() {
    const { firstLeg, lastLeg } = this.getFirstAndLastLeg()
    return (
      firstLeg.time &&
      firstLeg.time.type !== 'unknown' &&
      firstLeg.time.value &&
      lastLeg.endTime &&
      lastLeg.endTime.type !== 'unknown' &&
      lastLeg.endTime.value
    )
  }

  getFirstAndLastLeg(): { firstLeg: ConnectionLeg; lastLeg: ConnectionLeg } {
    const connectionLegs = this.getConnectionLegs()
    const firstLeg = connectionLegs[0]
    const lastLeg = connectionLegs[connectionLegs.length - 1]
    return { firstLeg, lastLeg }
  }

  getDurationForUi() {
    const { firstLeg, lastLeg } = this.getFirstAndLastLeg()

    try {
      if (this.hasStartAndEndTime()) {
        const diff = moment(lastLeg.endTime.value).diff(
          moment(firstLeg.time.value),
        )
        return (moment.duration(diff, 'milliseconds') as any).format(
          'h [h] mm [min]',
          {
            usePlural: false,
          },
        )
        // return moment.utc(duration.as('milliseconds')).format('HH:mm')
      }
      return null
    } catch (err) {}
    return ''
  }

  getArrivalTime(): string {
    const connectionLegs = this.getConnectionLegs()
    const lastLeg = connectionLegs[connectionLegs.length - 1]
    return lastLeg.getEndTime()
  }

  getToForUi() {
    return this.legs[this.legs.length - 1].getMunicipalityTo()
  }

  getToStopForUi() {
    return this.legs[this.legs.length - 1].getStopTo()
  }

  getFromStopForUi() {
    return this.legs[0].getStopFrom()
  }

  getPriceForUi() {
    if (this.price && this.price.value) {
      return `${this.price.value.toFixed(2)} €`
    }

    return 'N/A'
  }

  getStartTime() {
    const firstConnectionLeg = this.getConnectionLegs()[0]

    if (
      firstConnectionLeg.time.type !== 'unknow' &&
      firstConnectionLeg.time.value
    ) {
      return moment.parseZone(firstConnectionLeg.time.value)
    }
    return null
  }

  getStartMinutes(): number | null {
    const startTime = this.getStartTime()
    if (startTime) {
      return startTime
        .clone()
        .diff(startTime.clone().startOf('day'), 'minutes', true)
    }
    return null
  }
}

class ConnectionLeg implements IConnectionLeg {
  id?: string
  refId?: string
  actions!: Array<Action>
  direction?: string
  distance?: Distance
  duration?: Distance
  emissions?: Emission
  endTime!: Time
  from!: Place
  internalLegs?: Array<ConnectionLeg>
  notes?: Array<Note>
  operator?: string
  operatorBrandAllowed?: boolean
  operatorLabel?: MultiLanguageString
  operatorUrl?: MultiLanguageString
  provider!: string
  providerUrl?: MultiLanguageString
  providerLabel?: MultiLanguageString
  retrievedTime!: Date
  switches?: number
  time!: Time
  to!: Place
  transport!: Array<string>
  kind!: string
  vehicle?: string
  walkDistance?: Distance
  type: 'ConnectionLeg'
  price?: Price
  availability?: string
  amenities?: Array<string>
  legalNotices?: Array<LegalNotice>
  weights?: Array<Weight>
  logo?: string

  constructor(connectionLeg: IConnectionLeg) {
    Object.assign(this, connectionLeg)
    this.type = 'ConnectionLeg'
  }

  getTime(): string {
    if (moment(this.time.value).isValid()) {
      return moment.parseZone(this.time.value).format('HH:mm')
    }
    return 'N/A'
  }

  getEndTime(): string {
    if (moment(this.endTime.value).isValid()) {
      return moment.parseZone(this.endTime.value).format('HH:mm')
    }
    return 'N/A'
  }

  getMunicipalityTo() {
    if (this.to.municipality) {
      return this.to.municipality.name
    }
    return null
  }

  getStopTo() {
    return this.to.string
  }

  getStopFrom() {
    return this.from.string
  }
}

class GenericLocalLeg implements IGenericLocalLeg {
  from: Place
  to: Place
  type: 'GenericLocalLeg'

  constructor(leg: IGenericLocalLeg) {
    Object.assign(this, leg)
    this.from = leg.from
    this.to = leg.to
    this.type = 'GenericLocalLeg'
  }

  getMunicipalityTo() {
    if (this.to.municipality) {
      return this.to.municipality.name
    }
    return null
  }

  getStopTo() {
    return null
  }

  getStopFrom() {
    return null
  }
}

export default Connection
