import { Theme } from '../styled';

export default {
  color: {
    primary: '#b20066',
    background: '#ECEDF4',
    lightBg: '#CBD2DF',
    darkText: '#1C293E',
  },
} as Theme;
