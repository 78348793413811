import React, { useEffect, useState, useLayoutEffect, Fragment } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
// import QRCode from 'qrcode.react'
import queryString from 'query-string'
import {
  ResultsPageStyles,
  ResultsList,
  LoaderContainer,
  AdContainer,
  DateHeader,
} from './ResultsPage.styles'
import ResultsPageHeader from '../../components/ResultsPageHeader/ResultsPageHeader'
import { RouteComponentProps } from 'react-router'
import { getAllConnections, getPlaces, getRoutes } from './apiHelpers'
import Connection from '../../models/Connection'
import ConnectionItem from '../../components/ConnectionItem/ConnectionItem'
import { byDepartureTime, cars, filterPastConnections } from './filters'
import './styles.css'
import perilleIcon from './perille-icon.svg'
import moment from 'moment'
import Footer from '../../components/Footer/Footer'

interface RouteParams {
  from: string
  to: string
}

const adTimeMS = 5000

const ResultsPage: React.FC<RouteComponentProps<RouteParams>> = ({
  match,
  location,
}) => {
  const [connections, setConnections] = useState<Connection[] | null>(null)
  const [fetching, setFetching] = useState(true)
  const [showAd, setShowAd] = useState(false)
  const isFirstFetch = React.useRef(true)

  useLayoutEffect(() => {
    const { fontSize } = queryString.parse(location.search)

    if (fontSize) {
      document.documentElement.style.fontSize = fontSize as string
    }

    return () => {
      document.documentElement.style.fontSize = 'initial'
    }
  }, [location.search])

  useEffect(() => {
    document.body.style.backgroundColor = '#CBD2DF'

    return () => {
      document.body.style.backgroundColor = 'initial'
    }
  }, [location.search])

  // fetch connections
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    fetchAll()
    async function fetchAll() {
      const { fromObject, toObject } = await getPlaces(
        match.params.from,
        match.params.to,
      )
      const routes = await getRoutes(fromObject, toObject)
      const connectionResponses = await getAllConnections(routes)
      const filteredConnections = connectionResponses
        .map((c) => new Connection(c))
        .sort(byDepartureTime)
        .filter(cars)
        .slice(0, 10)

      // ad demo
      if (!isFirstFetch.current) {
        setConnections([])
        setShowAd(true)
        await new Promise((res) => setTimeout(res, adTimeMS))
        setShowAd(false)
      }

      setFetching(false)
      setConnections(filteredConnections)
      isFirstFetch.current = false
      timeoutId = setTimeout(fetchAll, 60 * 1000 * 10)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [match])

  // filter past connections
  useEffect(() => {
    const intervalId = setInterval(() => {
      setConnections(filterPastConnections)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const connectionsFound = !!(connections && connections.length)
  const firstTomorrowConnection = connections?.findIndex((c) =>
    c.getStartTime()?.isAfter(moment(), 'day'),
  )
  const content = showAd ? (
    <AdContainer>
      <div>
        <img src={perilleIcon} alt="Perille" />
      </div>
      <p>Matkatiedot tarjoaa perille.fi</p>
    </AdContainer>
  ) : fetching ? (
    <LoaderContainer>Haetaan matkoja...</LoaderContainer>
  ) : connectionsFound ? (
    <ResultsList>
      <TransitionGroup className="results-list">
        {connections!.map((c, i) => {
          return (
            <Fragment key={c.id}>
              {i === firstTomorrowConnection && (
                <DateHeader>
                  Huomenna {c.getStartTime()?.format('D.M.')}
                </DateHeader>
              )}
              <CSSTransition timeout={500} classNames="item">
                <ConnectionItem connection={c} />
              </CSSTransition>
            </Fragment>
          )
        })}
      </TransitionGroup>
    </ResultsList>
  ) : (
    <p>Ei tulevia matkoja</p>
  )

  return (
    <ResultsPageStyles>
      <ResultsPageHeader from={match.params.from} to={match.params.to} />
      {content}
      {/* <QRCode
        value={`https://perille.fi/matkahaku/${match.params.from}/${match.params.to}`}
      /> */}
      <Footer />
    </ResultsPageStyles>
  )
}

export default ResultsPage
