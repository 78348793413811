import React from 'react'
import { HeaderStyles } from './Header.styles'

interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return <HeaderStyles>{title}</HeaderStyles>
}

export default Header
