import moment from 'moment'

import { autoComplete, findRoutes, findConnections } from '../../api'
import { Route, Connection, Place } from '../../types/api/generatedTypes'

// import cons from './cons'

const searchSettings = {
  passengers: {
    adults: 1,
  },
  date: moment()
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss') as any,
  options: {},
}

export async function getAllConnections(
  routes: Route[],
): Promise<Connection[]> {
  // MOCK
  // return new Promise(res => {
  //   res(cons.connections)
  // })
  return (
    await Promise.all(
      routes.map(async route => {
        const [{ connections: today }, { connections: tomorrow }] = await Promise.all([
          findConnections({
            ...searchSettings,
            route,
          }),
          getTomorrowConnections(route),
        ]);
        return { connections: [...today, ...tomorrow] };
      }),
    )
  ).reduce<Connection[]>((prev, cur) => {
    return [...prev, ...cur.connections]
  }, [])
}

async function getTomorrowConnections(route: Route) {
  const currentHours = moment().hours() || new Date().getHours();

  if (currentHours >= 17) {
    return findConnections({
      ...searchSettings,
      date: moment()
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss') as any,
      route,
    })
  }

  return { connections: [] }
}

export async function getPlaces(
  from: string,
  to: string,
): Promise<{ fromObject: Place; toObject: Place }> {
  const [[fromObject], [toObject]] = await Promise.all([
    autoComplete(from),
    autoComplete(to),
  ])

  return { fromObject, toObject }
}

export async function getRoutes(
  fromObject: Place,
  toObject: Place,
): Promise<Route[]> {
  const { routes } = await findRoutes({
    from: fromObject,
    to: toObject,
    date: moment()
      // .add(1, 'day')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss') as any,
    routerConfig: {
      includeProviders: [
        'VR',
        'OnniBus',
        'Matkahuolto',
        'Savonlinja',
        'PoriExpress',
        'Pohjolan Liikenne',
      ],
      minIcLegCount: 1,
      maxIcLegCount: 1,
      excludeRoutesWithLocalLegs: true,
      localLegPolicy: 'municipality',
    },
  })

  return routes
}
