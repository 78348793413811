import styled from '../../styled'
import { cardWidths } from '../../cssVariables'

export const HeaderStyles = styled.header`
  background-color: ${(props) => props.theme.color.background};
`

export const Destination = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.color.darkText};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  p {
    margin: 0;
  }
`

export const Icon = styled.div`
  width: 80px;
  padding: 0 20px;
`

export const Left = styled.div`
  width: 20%;
  text-align: left;
`

export const Center = styled.div`
  width: 60%;
  text-align: center;
`

export const Right = styled.div`
  width: 20%;
  text-align: right;
  font-size: 2.25rem;
`

export const HeaderTopPart = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.color.darkText};
  text-align: center;
  font-size: 2rem;
  padding: 0.5rem;

  h1 {
    font-size: 2rem;
    margin: 0;
    color: ${(props) => props.theme.color.darkText};
  }

  span {
    font-weight: 600;
  }
`

export const HeaderTitlesPart = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  display: flex;
  margin-top: 0.2em;
  color: white;
  font-size: 1.25rem;
  padding: 1.25rem;
  font-weight: 600;

  div:nth-of-type(1) {
    width: ${cardWidths.provider};
  }

  div:nth-of-type(2) {
    width: ${cardWidths.leaves};
  }

  div:nth-of-type(3) {
    width: ${cardWidths.arrives};
  }

  & > div:nth-of-type(4) {
    width: ${cardWidths.duration};
  }

  div:nth-of-type(5) {
    width: ${cardWidths.destination};
  }

  div:nth-of-type(6) {
    width: ${cardWidths.price};
    display: flex;
    justify-content: flex-end;
  }
`

export const Line = styled.div`
  height: 1px;
  background-color: #ffffff;
  opacity: 0.6;
`
