import styled from '@emotion/styled'

export const SearchPageStyles = styled.main`
  display: block;
`

export const FormContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`
