import axios, { AxiosRequestConfig } from 'axios'

const apiToken = 'u0nT1z8pi6EKo4rfHt8T86Ysypq3xIXA'

axios.interceptors.request.use(
  (config): AxiosRequestConfig => {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  },
  error => Promise.reject(error),
)

export default axios
