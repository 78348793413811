import axios from './axios'
import {
  Place,
  FindRoutesRequest,
  FindRoutesResponse,
  FindConnectionsRequest,
  FindConnectionsResponse,
} from '../types/api/generatedTypes'
import { AutoCompleteParams } from './apiTypes'

const baseOptions = {
  application: 'perilleDisplay',
}

export const baseUrl = process.env.API_URL || 'https://screen-api.perille.fi/v2'
// export const baseUrl = 'http://localhost:8080/api'

console.log(process.env.API_URL)

export function autoComplete(
  value: string,
  { type, lang, country }: AutoCompleteParams = {},
): Promise<Place[]> {
  return axios
    .get<Place[]>(`${baseUrl}/autocomplete/${value}`, {
      params: {
        lang,
        type,
        country,
      },
    })
    .then(({ data }) => data)
}

export function findRoutes(
  params: FindRoutesRequest,
): Promise<FindRoutesResponse> {
  return axios
    .post<FindRoutesResponse>(`${baseUrl}/findroutes`, {
      ...params,
      options: { ...baseOptions },
    })
    .then(({ data }) => data)
}

export function findConnections(params: FindConnectionsRequest) {
  return axios
    .post<FindConnectionsResponse>(`${baseUrl}/findconnections`, {
      ...params,
      options: { ...baseOptions },
    })
    .then(({ data }) => data)
}
