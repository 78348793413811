import React from 'react'
import Connection from '../../models/Connection'
import { ConnectionItemStyles, Vehicle } from './ConnectionItem.styles'
import { getProvidersImageUrl } from './helpers'

interface ConnectionItemProps {
  connection: Connection
}

const ConnectionItem: React.FC<ConnectionItemProps> = ({ connection }) => {
  return (
    <ConnectionItemStyles>
      <div>
        <img
          src={getProvidersImageUrl(connection.getLogoProviders())}
          alt={connection.getLogoProviders().join(',')}
        />
        {getVehicle(connection) && <Vehicle>{getVehicle(connection)}</Vehicle>}
      </div>
      <div>{connection.getDepartureTime()}</div>
      <div>{connection.getArrivalTime()}</div>
      <div>{connection.getDurationForUi()}</div>
      <div>{connection.getFromStopForUi()}</div>
      <div>{connection.getPriceForUi()}</div>
    </ConnectionItemStyles>
  )
}

export default ConnectionItem

function getVehicle({
  legs: [firstLeg],
  legs,
}: Connection): string | undefined {
  if (legs.length === 1 && firstLeg.type === 'ConnectionLeg') {
    return firstLeg.vehicle
  }
}
