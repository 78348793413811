export function getProvidersImageUrl(providers: string[]): string {
  const newProviders = providers.map(provider => {
    if (provider.split(' ').length > 1) {
      return provider
        .split(' ')
        .map(p => p.toLowerCase())
        .join('')
    }
    return provider
      .split(' ')
      .join('')
      .toLowerCase()
  })
  return `https://static.perille.fi/logo/${newProviders.join('-')}_square.png`
}
