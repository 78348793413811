import React from 'react'
import Logo from '../Logo/Logo'
import { FooterDiv } from './Footer.styles'

const Footer = () => {
  return (
    <FooterDiv>
      <Logo />
    </FooterDiv>
  )
}

export default Footer
