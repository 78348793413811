import styled from '../../styled'

export const HeaderStyles = styled.header`
  background-color: ${props => props.theme.color.primary};
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.2em 0;
`
