import moment from 'moment'
import Connection from '../../models/Connection'

export function byDepartureTime(a: Connection, b: Connection): 1 | -1 | 0 {
  const startA = a.getStartTime() || Number.MAX_VALUE
  const startB = b.getStartTime() || Number.MAX_VALUE

  return startA > startB
    ? 1
    : startB > startA
      ? -1
      : 0
}

export function cars(connection: Connection): boolean {
  const leg = connection.legs[0]
  if (leg.type === 'ConnectionLeg') {
    if (leg.provider === 'Taxi' || leg.provider === 'Private vehicle') {
      return false
    }
  }
  return true
}

export function filterPastConnections(connections: Connection[] | null) {
  if (connections && connections.length) {
    return connections.filter(c =>
      c.getStartTime()?.isSameOrAfter(moment().startOf('minute')),
    )
  }

  return connections
}
