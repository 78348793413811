import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const bounce = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
`

export const ResultsPageStyles = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const ResultsList = styled.div`
  flex: 1;
  overflow-y: auto;
  animation: ${fadeIn} 1s ease;
`

export const LoaderContainer = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  animation: ${bounce} 1s ease infinite;
`

export const AdContainer = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  p {
    margin: 20px 0;
    color: black;
    font-size: 2rem;
    font-size: grey;
  }
`

export const DateHeader = styled.h2`
  color: #1c293e;
  font-size: 2rem;
  text-align: center;
  margin: 0.5rem 0rem;
`
