import styled from '../../styled'

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.primary};

  img {
    width: 100px;
  }

  p {
    font-weight: 600;
    font-size: 2rem;
    margin: 0;
  }
`
