import React from 'react'
import moment from 'moment'
import {
  HeaderStyles,
  HeaderTopPart,
  HeaderTitlesPart,
  Destination,
  Left,
  Right,
  Icon,
} from './ResultsPageHeader.styles'
import { ReactComponent as NextIcon } from './next.svg'
import Logo from '../Logo/Logo'

interface HeaderProps {
  from: string
  to: string
}

const ResultsPageHeader: React.FC<HeaderProps> = ({ from, to }) => {
  const timeString = useTime()

  return (
    <HeaderStyles>
      <HeaderTopPart>
        <Left>
          <Logo />
        </Left>
        <Destination>
          <p>{from}</p>
          <Icon>
            <NextIcon style={{ display: 'block' }} />
          </Icon>
          <p>{to}</p>
        </Destination>
        <Right>
          <span>{timeString}</span>
        </Right>
      </HeaderTopPart>

      <HeaderTitlesPart>
        <div>Linja</div>
        <div>Lähtee</div>
        <div>Saapuu</div>
        <div>Kesto</div>
        <div>Lähtöasema</div>
        <div>Hinta</div>
      </HeaderTitlesPart>
    </HeaderStyles>
  )
}

export default ResultsPageHeader

const useTime = () => {
  const [timeString, setTimeString] = React.useState()

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeString(moment().format('HH:mm:ss'))
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return timeString
}
