import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import themes from './themes'

import HomePage from './pages/SearchPage/SearchPage'
import ResultsPage from './pages/ResultsPage/ResultsPage'

const App: React.FC = props => {
  return (
    <div className="App">
      <ThemeProvider theme={themes.perille}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/results/:from/:to" component={ResultsPage} />
        </Switch>
      </ThemeProvider>
    </div>
  )
}

export default App
