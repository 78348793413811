import styled from '@emotion/styled'
import { cardWidths } from '../../cssVariables'

export const Vehicle = styled.p`
  font-size: 1.125rem;
`

export const ConnectionItemStyles = styled.div`
  display: flex;
  min-height: 20px;
  margin: 0.5rem 0rem;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 0.75em;

  & > div {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: #1c293e;
    font-weight: 600;
  }

  p {
    margin: 0;
  }

  & > div:nth-of-type(1) {
    width: ${cardWidths.provider};
    flex-wrap: wrap;

    & > * {
      margin-right: 10px !important;
    }

    img {
      width: 3rem;
    }
  }

  & > div:nth-of-type(2) {
    width: ${cardWidths.leaves};
  }

  & > div:nth-of-type(3) {
    width: ${cardWidths.arrives};
  }

  & > div:nth-of-type(4) {
    width: ${cardWidths.duration};
    /* font-size: 1.5rem; */
  }

  & > div:nth-of-type(5) {
    width: ${cardWidths.destination};
    font-size: 1.5rem;
  }

  & > div:nth-of-type(6) {
    width: ${cardWidths.price};
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
`
