import { useState, useEffect } from 'react'
import { Place } from '../types/api/generatedTypes'
import { autoComplete } from '../api'
import useDebounce from './useDebounce'

export const useAutoComplete = (
  searchTerm: string,
): { isFetching: boolean; places: Place[] } => {
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const [places, setPlaces] = useState<Place[]>([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    async function search() {
      if (debouncedSearchTerm) {
        setIsFetching(true)
        const places = await autoComplete(debouncedSearchTerm)
        setIsFetching(false)
        setPlaces(places)
      } else {
        setPlaces([])
      }
    }
    search()
  }, [debouncedSearchTerm])

  return { isFetching, places }
}
